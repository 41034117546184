::placeholder {
  color: #666;
}

::selection {
  background-color: $accent;
  color: #fff;
}

* {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $default-font;
  min-width: 320px;
  overflow-x: hidden;
  font-weight: 400;

  &.noscroll {
    overflow-y: hidden !important;
  }

  input,
  textarea {
    border: #666 1px solid;
    outline: none;
    &:focus:required:invalid {
      border-color: red;
    }
    &:required:valid {
      border-color: green;
    }
  }
}

.overflowHidden {
  overflow: hidden;
  position: fixed;
}

a {
  display: block;
  text-decoration: none;
  transition: all 0.5s ease;
  color: $main-black;

  &:hover {
    color: $accent;
  }
}

p {
  font-size: 1rem;
  line-height: + rem(26px);
  color: $main-black;
}

.main {
  margin-top: 79px;
}

.container {
  padding: 0 15px;
  height: 100%;

  @include tablets {
    padding: 0 30px;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.visuallyhidden:not(:focus):not(:active) {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.button {
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 160px;
  background-color: $main-white;
  text-transform: uppercase;
  text-decoration: none;
  color: $main-black;
  font-weight: 600;
  font-size: + rem(12px);
  transition: all 0.5s ease;

  &:hover {
    background-color: $main-black;
    color: $main-white;
  }

  &-brown {
    background-color: $accent;
    color: $main-white;
    text-transform: none;

    &:hover {
      background-color: $main-white;
      color: $accent;
    }
  }
}

$white: #fff;
$secondary: #adb5bd;
$orange: #d9963c;

.checkbox-box {
  display: flex;
  //align-items: flex-start;
  cursor: pointer;
  margin: 0 0 10px;
  user-select: none;

  .checkbox-check {
    width: 16px;
    height: 16px;
    background-color: $input-focus;
  }

  &:hover {
    .checkbox-check {
      background-color: $input-focus;
    }
  }

  input {
    &[type="checkbox"] {
      display: none;

      &:checked {
        + .checkbox-check {
          background-color: $input-focus;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .checkbox-content {
    display: inline-flex;
    text-align: left;
    margin: 0;
    font-size: +rem(11px);
    line-height: +rem(15px);
    font-weight: 400;
    color: #ffe7c3;
  }

  .checkbox-check {
    min-width: 16px;
    max-width: 16px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    background-color: $input-bg;
    border-radius: 50%;
    transition: all 0.5s ease;

    &:after {
      opacity: 0;
      content: "\e902";
      font-family: "lightpoint";
      font-size: + rem(7px);
      transition: all 0.5s ease;
    }
  }
}

.section__title {
  font-size: + rem(36px);
  font-weight: 600;
  line-height: + rem(50px);
  margin: 0;

  span {
    font-weight: 400;
  }

  @include phones {
    font-size: +rem(30px);
    line-height: + rem(40px);
  }
}

.logo {
  width: 160px;
  height: 12px;
  display: block;

  .logo__img {
    width: 100%;
    height: 100%;
    display: block;
  }

  &-inverse {
    .logo__img {
      fill: $main-white;
    }
  }
}

section.section {
  @include phones {
    padding: 60px 0;
  }
}

