.mobile-menu {
  background: #fff;
  margin-top: 0;
  position: fixed;
  top: -130%;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  will-change: transform;
  transition: 0.5s;
}

.mobile {
  @media screen and (min-width: 769px) {
    display: none;
  }
}

.mobile-menu_open {
  top: 0;
  bottom: 0;
  transition: 0.5s;
}

.mobile-menu__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;

  a {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 60px;

    &.active {
      color: $accent;
    }
  }
}

.mobile-menu__topline {
  padding: 22px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;

  .contact__item {
    margin: 0 auto;
  }
}

.mobile-menu__item {
  width: 100%;
  transition: all 0.3s ease;
  &:hover,
  &.active {
    background-color: rgba(232, 232, 232, 0.4);
  }
}

.mobile-menu__href {
  color: red;
  font-family: "Arial";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 55px;
  transition: 0.3s;
  font-weight: 600;
}

.burger {
  $width: 28px;
  $thickness: 4px;

  position: relative;
  z-index: 99999;
  border: 0;
  color: $accent;
  background: none;
  outline: 0;
  padding: 0;
  cursor: pointer;

  border-bottom: $thickness solid currentColor;
  width: $width;

  transition: border-bottom 1s ease-in-out;
  -webkit-transition: border-bottom 1s ease-in-out;

  // Fix for extra space in Firefox
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:before {
    content: "";
    display: block;
    border-bottom: $thickness solid currentColor;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }

  &:after {
    content: "";
    display: block;
    border-bottom: $thickness solid currentColor;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }

  &.open {
    border-bottom: $thickness solid transparent;
    transition: border-bottom 0.8s ease-in-out;
    -webkit-transition: border-bottom 0.8s ease-in-out;

    &:before {
      transform: rotate(-405deg) translateY(1px) translateX(-3px);
      -webkit-transform: rotate(-405deg) translateY(1px) translateX(-3px);
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
    }

    &:after {
      transform: rotate(405deg) translateY(-4px) translateX(-5px);
      -webkit-transform: rotate(405deg) translateY(-4px) translateX(-5px);
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
    }
  }
}
