.footer {
  padding: 48px 0;
  background-color: $dark-gray;
  color: $main-white;

  @include phones {
    padding: 30px 0;
  }

  .section__wrapper {
    max-width: 1600px;
  }

  &-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    @include phones {
      display: none;
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include phones {
      flex-direction: column;

      .footer__copy {
        order: 3;
        margin: 0;
      }

      .footer__link {
        margin-bottom: 15px;
      }

      .footer__contacts {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .contact__item {
          margin-bottom: 15px;
        }
      }
    }
  }

  .logo {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__nav {
    margin-left: 5%;
    align-self: center;
  }

  &__copy, &__link {
    display: block;
    font-size: +rem(11px);
    font-weight: 400;
    color: rgba(255, 255, 255, 0.61);
  }

  &__contacts {
    display: flex;
    align-items: center;
  }

  .contact__link {
    &:first-of-type {
      margin-right: +rem(32px);
    }
  }

  .nav__link {
    color: $main-white;

    &.active, &:hover {
      color: $accent;
    }
  }

  @include tablets {

    padding: 3rem 0;

    .logo-inverse {
      position: static;
      margin: 0 auto;
    }

    .footer__nav {
      display: none;
    }

    .footer-bottom {
      flex-wrap: wrap;
    }

    .footer__copy {
      width: 100%;
      order: 1;
      text-align: center;
      margin: 0;
    }

    .footer__contacts {
      margin: 1.3rem 0;
    }
  }
}