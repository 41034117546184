@font-face {
  font-family: "lightpoint";
  src: url("../fonts/LightPoint/LightPoint.eot?lldbgx");
  src: url("../fonts/LightPoint/LightPoint.eot?lldbgx#iefix")
      format("embedded-opentype"),
    url("../fonts/LightPoint/LightPoint.ttf?lldbgx") format("truetype"),
    url("../fonts/LightPoint/LightPoint.woff?lldbgx") format("woff"),
    url("../fonts/LightPoint/LightPoint.svg?lldbgx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lightpoint" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\e900";
}

.icon-arrow-right:before {
  content: "\e901";
}

.icon-check:before {
  content: "\e902";
}

.icon-close-b-n:before {
  content: "\e903";
}

.icon-e-mail-icon:before {
  content: "\e904";
}

.icon-minus-icon:before {
  content: "\e905";
}

.icon-Phone-icon:before {
  content: "\e906";
}

.icon-play-icon:before {
  content: "\e907";
}

.icon-plus-icon:before {
  content: "\e908";
}
