.section-company {
  color: $main-white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 700px;
  background-position: center;

  .section__title {
    margin-bottom: +rem(30px);
  }

  @include phones {
    height: auto;
    min-height: auto;

    .items__container {
      flex-direction: column;
      align-items: center;
    }

    .item {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;

      br {
        display: none;
      }
    }
  }
}

.items__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.item {
  &__count {
    font-size: +rem(80px);

    @include phones {
      font-size: +rem(64px);
    }
  }

  &__desc {
    line-height: +rem(26px);
    font-size: 1rem;
    color: $main-white;
  }
}

.section__wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
}

.section-company {
  @include tablets{

    .items__container {
      flex-wrap: wrap;

      .item {
        width: 50%;
        text-align: center;
        margin-bottom: 2rem;
      }

      .item__count {
        font-size: 4rem;
      }
    }
  }
}