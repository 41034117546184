.nav {
  display: flex;

  &__link {
    display: block;
    font-size: +rem(12px);
    color: $main-black;
    font-weight: 600;
    text-transform: uppercase;
    transition: all .5s ease;

    &:not(:last-of-type){
      margin-right: 40px;
    }
  }

}