$default-font: "montserrat", sans-serif;

$accent: #B39158;
$input-bg: rgba(123, 93, 48, 0.65);
$input-focus: #7B5D30;
$main-red: #FF0000;
$main-black: #000;
$main-white: #fff;
$dark-gray: #2D2D2D;
$gray: #5A5A5A;

$mobile-width: 320px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1366px;