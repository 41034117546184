.order-form {
  min-width: 320px;
  max-width: 30%;
  width: 100%;
  background-color: #B39158;
  padding: 4rem 2rem 2rem;

  .fancybox-close-small {
    left: auto;
    right: 0;
    top: .5rem;

    svg {
      fill: rgb(123, 93, 48);
      stroke: rgb(123, 93, 48);
    }
  }

  .inputs__holder {
    flex-direction: column;
    margin-bottom: 1rem;

    .form-input {
      max-width: 100%;
      width: 100%;
      background-color: rgba(123, 93, 48, 0.65);
      margin-bottom: 1.5rem;
    }
  }
  .button {
    display: inline-flex;
    float: right;
  }
}