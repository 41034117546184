.contact {
  &__item {
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: +rem(23px);
    color: $accent;
    margin-right: +rem(14px);
  }

  &__link {
    display: block;
    font-size: +rem(16px);
    font-weight: 600;
    transition: all 0.3s ease;
    color: $main-black;

    &.white {
      color: $main-white;
    }

    &:hover {
      color: $accent;
    }
  }
}
