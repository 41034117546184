.section-form {
  background-color: $accent;
  color: $main-white;
  padding-top: 185px;
  padding-bottom: 72px;

  .section__wrapper {
    max-width: 992px;
    width: 100%;
    text-align: center;
  }

  .section__title {
    margin-bottom: 50px;
    @include phones {
      font-size: 1.125rem;
      line-height: 2rem;
      br {
        display: none;
      }
    }
  }

  .button {
    display: inline-block;
    margin-bottom: 56px;
  }

  .checkbox-container {
    max-width: 640px;
    margin: 0 auto;
  }

  @include phones {
    .inputs__holder {
      flex-direction: column;
      margin-bottom: 2.5rem;
    }

    .form-input {
      margin: 0 auto 1rem;
      border-radius: 0;
    }
  }
}

.inputs__holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: +rem(80px);
}

.form-input {
  font-family: $default-font;
  background-color: $input-bg;
  font-size: +rem(12px);
  line-height: +rem(15px);
  color: $main-white;
  border: 2px solid transparent !important;
  padding: 13px 25px;
  max-width: 320px;
  width: 100%;
  transition: all 0.5s ease;

  &:focus {
    background: $input-focus;
  }

  &::-webkit-input-placeholder {
    font-family: $default-font;
    color: $main-white;
    font-size: +rem(12px);
    font-weight: 400;
  }
  &::-moz-placeholder {
    font-family: $default-font;
    color: $main-white;
    font-size: +rem(12px);
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    font-family: $default-font;
    color: $main-white;
    font-size: +rem(12px);
    font-weight: 400;
  }
  &:-moz-placeholder {
    font-family: $default-font;
    color: $main-white;
    font-size: +rem(12px);
    font-weight: 400;
  }
}

.section.section-form {
  @include tablets {
    padding: 4rem 0;

    .inputs__holder {
      flex-direction: column;
      margin: 0 auto;
      max-width: 75%;
      margin-bottom: 2rem;

      .form-input {
        max-width: 100%;
        margin-bottom: 1rem;
        border-radius: 0;
      }
    }

    .checkbox-container {
      margin-bottom: 3rem;
      max-width: 75%;
    }

    .button {
      margin: 0;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding: 6rem 0;
  }
}
