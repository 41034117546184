.section-hero {
  height: 100vh;
  background-position: center;
  background-size: cover;

  .section__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include phones {
      height: calc(100% - 150px);
    }

    @include tablets {
      text-align: center;

      .button {
        margin: 0 auto;
      }
    }
  }
}

.hero {
  &__title {
    font-size: +rem(48px);
    color: $main-white;
    font-weight: 300;
    line-height: +rem(66px);

    @include phones{
      font-size: +rem(26px);
      line-height: +rem(36px);
      br {
        display: none;
      }
    }

    b {
      font-weight: 600;
    }
  }

  &__text {
    font-size: +rem(18px);
    color: $main-white;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: +rem(74px);

    @include phones{
      font-size: +rem(16px);
      margin-bottom: +rem(50px);
    }
  }
}
