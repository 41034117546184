.header {
  height: 79px;
  background-color: $main-white;
  width: 100%;
  position: fixed;
  z-index: 1000;

  @include phones {
    border-bottom: 1px solid $gray;
  }

  @include tablets {
    border-bottom: 1px solid $gray;
  }

  .container {
    height: 100%;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding: 28px 0;
  }

  &__nav {
    height: 100%;
    .nav {
      height: 100%;
    }

    @include phones {
      display: none;
    }
    @include tablets {
      display: none;
    }
  }

  &__contacts {
    @include phones {
      display: none;
    }

    @include tablets {
      display: none;
    }
  }

  .nav__link {
    display: inline-flex;
    position: relative;
    align-items: center;
    border-bottom: 5px solid transparent;

    &.active,
    &:hover {
      color: $accent;
      border-bottom: 5px solid $accent;
    }
  }
}
