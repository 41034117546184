@import "mixins";

@include font-face("montserrat", "../fonts/MontserratLight/MontserratLight", 300);
@include font-face("montserrat", "../fonts/MontserratRegular/MontserratRegular", 400);
@include font-face("montserrat", "../fonts/MontserratSemiBold/MontserratSemiBold", 600);
@include font-face("montserrat", "../fonts/MontserratBold/MontserratBold", 700);






