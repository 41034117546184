.section-registration {
  padding-top: + rem(160px);
  padding-bottom: + rem(165px);

  .section__title {
    font-weight: 300;
    margin-bottom: + rem(53px);

    b {
      font-weight: 600;
    }
  }



  .content__wrapper {
    width: 100%;
    background-color: $accent;
    display: flex;

    @include phones {
      flex-direction: column-reverse;
      background-color: $main-white;
    }

    @include tablets {
      flex-direction: column-reverse;
      background-color: $main-white;
      align-items: center;
    }
  }

  .form__holder {
    max-width: 480px;
    width: 100%;
    color: $main-white;
    text-align: center;
    padding: 4.6875rem 0;
    background-color: $accent;

    @include phones {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      padding: 60px 15px;
    }

    @include tablets {
      margin: 0 auto;
      max-width: 75%;
      padding: 4rem 0;
    }
  }

  .checkbox-container {
    max-width: 325px;
    margin: 0 auto;
  }

  .video__holder {
    flex: 1 0 auto;
    position: relative;
    max-width: 800px;
    overflow: hidden;
    @include phones {
      height: 345px;
      min-height: 345px;
      margin-bottom: 30px;
    }

    @include tablets {
      max-width: 75%;
      width: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;
    }

    @include tablets {
      height: 400px;
      min-height: 400px;
      margin-bottom: 30px;
    }
  }

  .video__button {
    display: flex;
    border: none;
    border-radius: 0;
    background-color: $main-white;
    align-items: center;
    justify-content: center;
    width: + rem(48px);
    height: + rem(48px);
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: all 0.5s ease;

    i {
      font-size: +rem(20px);
      color: $accent;
      transition: all 0.5s ease;
    }

    &:hover {
      background: $accent;
      i {
        color: $main-white;
      }
    }
  }

  .video__poster {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}

.form-registration {
  .inputs__holder {
    flex-direction: column;
    align-items: center;
    margin-bottom: + rem(40px);
  }

  .form-input {
    border: 2px solid transparent;
    border-radius: 0;
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .button {
    margin-bottom: + rem(56px);
  }

  .checkbox-holder {
    max-width: 330px;
    margin: 0 auto;
  }
}

.form__title {
  font-weight: 400;
  font-size: + rem(18px);
  line-height: 2rem;
  margin-top: 0;
  margin-bottom: + rem(50px);

  b {
    font-weight: 700;
  }
}

.section.section-registration {
  @include phones {
    padding-bottom: 0;
  }

  @include tablets {
    padding: 3rem 0;
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding: 4rem 0;
  }
}

.form-input.error {
  border: 2px solid red !important;
}

.help-block.form-error {
  display: none!important;
}

.btn-submit {
  width: 220px;
}

.link-politic {
  display: inline-flex;
  text-decoration: underline;
  color: #ffe7c3;
  outline: none;


  &:hover {
    color: $main-white;
  }
}


#politic {
  width: 100%;
  max-width: 50%;
  .fancybox-close-small {
    right: 0;
    left: auto;
    top: .5rem;
  }
}

.checkbox-box .checkbox-content {
  display: block;
}