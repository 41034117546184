@charset "UTF-8";
.hide {
  display: none !important; }

.hide {
  display: none !important; }

@font-face {
  font-family: "montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/MontserratLight/MontserratLight.eot");
  src: url("../fonts/MontserratLight/MontserratLight.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratLight/MontserratLight.woff") format("woff"), url("../fonts/MontserratLight/MontserratLight.ttf") format("truetype"); }

@font-face {
  font-family: "montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/MontserratRegular/MontserratRegular.eot");
  src: url("../fonts/MontserratRegular/MontserratRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratRegular/MontserratRegular.woff") format("woff"), url("../fonts/MontserratRegular/MontserratRegular.ttf") format("truetype"); }

@font-face {
  font-family: "montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/MontserratSemiBold/MontserratSemiBold.eot");
  src: url("../fonts/MontserratSemiBold/MontserratSemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratSemiBold/MontserratSemiBold.woff") format("woff"), url("../fonts/MontserratSemiBold/MontserratSemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/MontserratBold/MontserratBold.eot");
  src: url("../fonts/MontserratBold/MontserratBold.eot?#iefix") format("embedded-opentype"), url("../fonts/MontserratBold/MontserratBold.woff") format("woff"), url("../fonts/MontserratBold/MontserratBold.ttf") format("truetype"); }

@font-face {
  font-family: "lightpoint";
  src: url("../fonts/LightPoint/LightPoint.eot?lldbgx");
  src: url("../fonts/LightPoint/LightPoint.eot?lldbgx#iefix") format("embedded-opentype"), url("../fonts/LightPoint/LightPoint.ttf?lldbgx") format("truetype"), url("../fonts/LightPoint/LightPoint.woff?lldbgx") format("woff"), url("../fonts/LightPoint/LightPoint.svg?lldbgx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lightpoint" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-left:before {
  content: "\e900"; }

.icon-arrow-right:before {
  content: "\e901"; }

.icon-check:before {
  content: "\e902"; }

.icon-close-b-n:before {
  content: "\e903"; }

.icon-e-mail-icon:before {
  content: "\e904"; }

.icon-minus-icon:before {
  content: "\e905"; }

.icon-Phone-icon:before {
  content: "\e906"; }

.icon-play-icon:before {
  content: "\e907"; }

.icon-plus-icon:before {
  content: "\e908"; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before {
    display: table;
    content: ""; }
  .slick-track:after {
    display: table;
    content: "";
    clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover, .slick-prev:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-next:hover, .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before {
  opacity: 1; }

.slick-next:hover:before, .slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25; }

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: "←"; }

[dir="rtl"] .slick-prev:before {
  content: "→"; }

.slick-next {
  right: -25px; }

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: "→"; }

[dir="rtl"] .slick-next:before {
  content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:hover:before, .slick-dots li button:focus:before {
        opacity: 1; }
      .slick-dots li button:before {
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: black; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }
  .fancybox-container * {
    box-sizing: border-box; }

.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s, visibility 0s linear 0.25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity 0.25s, visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }
  .fancybox-slide:before {
    content: "";
    display: inline-block;
    height: 100%;
    margin-right: -0.25em;
    vertical-align: middle;
    width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0; }
  .fancybox-slide--image:before {
    display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0; }

.fancybox-slide--iframe {
  padding: 44px 44px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable="true"] {
  cursor: text; }

.fancybox-image {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 1; }

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px; }

.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%;
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px; }

.fancybox-iframe,
.fancybox-video {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  vertical-align: top; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }
  .fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0; }

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color 0.2s;
  vertical-align: top;
  width: 44px;
  color: #ccc; }
  .fancybox-button:link, .fancybox-button:visited {
    color: #ccc; }
  .fancybox-button:focus, .fancybox-button:hover {
    color: #fff; }
  .fancybox-button.disabled, .fancybox-button[disabled] {
    color: #888;
    cursor: default; }
    .fancybox-button.disabled:hover, .fancybox-button[disabled]:hover {
      color: #888;
      cursor: default; }
  .fancybox-button svg {
    display: block;
    overflow: visible;
    position: relative;
    shape-rendering: geometricPrecision; }
    .fancybox-button svg path {
      fill: transparent;
      stroke: currentColor;
      stroke-linejoin: round;
      stroke-width: 3; }

.fancybox-button--pause svg path:nth-child(1) {
  display: none; }

.fancybox-button--play svg path {
  fill: currentColor; }
  .fancybox-button--play svg path:nth-child(2) {
    display: none; }

.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
  fill: currentColor; }

.fancybox-button--share svg path {
  stroke-width: 1; }

.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity 0.25s, visibility 0s, color 0.25s; }

.fancybox-navigation .fancybox-button:after {
  content: "";
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10; }
  .fancybox-close-small svg {
    fill: transparent;
    opacity: 0.8;
    stroke: currentColor;
    stroke-width: 1.5;
    transition: stroke 0.1s; }
  .fancybox-close-small:focus {
    outline: none; }
  .fancybox-close-small:hover svg {
    opacity: 1; }

.fancybox-slide--iframe .fancybox-close-small,
.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--iframe .fancybox-close-small:hover svg,
.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px;
  right: 0; }
  .fancybox-caption:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
    background-repeat: repeat-x;
    background-size: contain;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -25px;
    z-index: -1; }
  .fancybox-caption:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    content: "";
    display: block;
    left: 44px;
    position: absolute;
    right: 44px;
    top: 0; }
  .fancybox-caption a {
    color: #ccc;
    text-decoration: none; }
    .fancybox-caption a:link, .fancybox-caption a:visited {
      color: #ccc;
      text-decoration: none; }
    .fancybox-caption a:hover {
      color: #fff;
      text-decoration: underline; }

.fancybox-loading {
  -webkit-animation: a 0.8s infinite linear;
  animation: a 0.8s infinite linear;
  background: transparent;
  border: 6px solid rgba(99, 99, 99, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: 0.6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-1turn);
  transform: rotate(-1turn); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg); }

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0); }

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1); }

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }
  .fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px; }
  .fancybox-share p {
    margin: 0;
    padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }
  .fancybox-share__button:link, .fancybox-share__button:visited {
    color: #fff; }
  .fancybox-share__button:hover {
    text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }
  .fancybox-share__button--fb:hover {
    background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }
  .fancybox-share__button--pt:hover {
    background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }
  .fancybox-share__button--tw:hover {
    background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }
  .fancybox-share__button svg path {
    fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x > ul {
  overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover; }
  .fancybox-thumbs > ul > li:before {
    border: 4px solid #4ea7f9;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 99991; }

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1; }

@media (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }

::placeholder {
  color: #666; }

::selection {
  background-color: #B39158;
  color: #fff; }

* {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "montserrat", sans-serif;
  min-width: 320px;
  overflow-x: hidden;
  font-weight: 400; }
  body.noscroll {
    overflow-y: hidden !important; }
  body input,
  body textarea {
    border: #666 1px solid;
    outline: none; }
    body input:focus:required:invalid,
    body textarea:focus:required:invalid {
      border-color: red; }
    body input:required:valid,
    body textarea:required:valid {
      border-color: green; }

.overflowHidden {
  overflow: hidden;
  position: fixed; }

a {
  display: block;
  text-decoration: none;
  transition: all 0.5s ease;
  color: #000; }
  a:hover {
    color: #B39158; }

p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #000; }

.main {
  margin-top: 79px; }

.container {
  padding: 0 15px;
  height: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
      padding: 0 30px; } }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.text-center {
  text-align: center; }

.visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

.button {
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 160px;
  background-color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  font-size: 0.75rem;
  transition: all 0.5s ease; }
  .button:hover {
    background-color: #000;
    color: #fff; }
  .button-brown {
    background-color: #B39158;
    color: #fff;
    text-transform: none; }
    .button-brown:hover {
      background-color: #fff;
      color: #B39158; }

.checkbox-box {
  display: flex;
  cursor: pointer;
  margin: 0 0 10px;
  user-select: none; }
  .checkbox-box .checkbox-check {
    width: 16px;
    height: 16px;
    background-color: #7B5D30; }
  .checkbox-box:hover .checkbox-check {
    background-color: #7B5D30; }
  .checkbox-box input[type="checkbox"] {
    display: none; }
    .checkbox-box input[type="checkbox"]:checked + .checkbox-check {
      background-color: #7B5D30; }
      .checkbox-box input[type="checkbox"]:checked + .checkbox-check::after {
        opacity: 1; }
  .checkbox-box .checkbox-content {
    display: inline-flex;
    text-align: left;
    margin: 0;
    font-size: 0.6875rem;
    line-height: 0.9375rem;
    font-weight: 400;
    color: #ffe7c3; }
  .checkbox-box .checkbox-check {
    min-width: 16px;
    max-width: 16px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    background-color: rgba(123, 93, 48, 0.65);
    border-radius: 50%;
    transition: all 0.5s ease; }
    .checkbox-box .checkbox-check:after {
      opacity: 0;
      content: "\e902";
      font-family: "lightpoint";
      font-size: 0.4375rem;
      transition: all 0.5s ease; }

.section__title {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 3.125rem;
  margin: 0; }
  .section__title span {
    font-weight: 400; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .section__title {
      font-size: 1.875rem;
      line-height: 2.5rem; } }

.logo {
  width: 160px;
  height: 12px;
  display: block; }
  .logo .logo__img {
    width: 100%;
    height: 100%;
    display: block; }
  .logo-inverse .logo__img {
    fill: #fff; }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  section.section {
    padding: 60px 0; } }

.nav {
  display: flex; }
  .nav__link {
    display: block;
    font-size: 0.75rem;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    transition: all .5s ease; }
    .nav__link:not(:last-of-type) {
      margin-right: 40px; }

.mobile-menu {
  background: #fff;
  margin-top: 0;
  position: fixed;
  top: -130%;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  will-change: transform;
  transition: 0.5s; }

@media screen and (min-width: 769px) {
  .mobile {
    display: none; } }

.mobile-menu_open {
  top: 0;
  bottom: 0;
  transition: 0.5s; }

.mobile-menu__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0; }
  .mobile-menu__wrap a {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 60px; }
    .mobile-menu__wrap a.active {
      color: #B39158; }

.mobile-menu__topline {
  padding: 22px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between; }
  .mobile-menu__topline .contact__item {
    margin: 0 auto; }

.mobile-menu__item {
  width: 100%;
  transition: all 0.3s ease; }
  .mobile-menu__item:hover, .mobile-menu__item.active {
    background-color: rgba(232, 232, 232, 0.4); }

.mobile-menu__href {
  color: red;
  font-family: "Arial";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 55px;
  transition: 0.3s;
  font-weight: 600; }

.burger {
  position: relative;
  z-index: 99999;
  border: 0;
  color: #B39158;
  background: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  border-bottom: 4px solid currentColor;
  width: 28px;
  transition: border-bottom 1s ease-in-out;
  -webkit-transition: border-bottom 1s ease-in-out; }
  .burger::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .burger:before {
    content: "";
    display: block;
    border-bottom: 4px solid currentColor;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out; }
  .burger:after {
    content: "";
    display: block;
    border-bottom: 4px solid currentColor;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out; }
  .burger.open {
    border-bottom: 4px solid transparent;
    transition: border-bottom 0.8s ease-in-out;
    -webkit-transition: border-bottom 0.8s ease-in-out; }
    .burger.open:before {
      transform: rotate(-405deg) translateY(1px) translateX(-3px);
      -webkit-transform: rotate(-405deg) translateY(1px) translateX(-3px);
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out; }
    .burger.open:after {
      transform: rotate(405deg) translateY(-4px) translateX(-5px);
      -webkit-transform: rotate(405deg) translateY(-4px) translateX(-5px);
      transition: transform 0.5s ease-in-out;
      -webkit-transition: -webkit-transform 0.5s ease-in-out; }

.contact__item {
  display: flex;
  align-items: center; }

.contact__icon {
  font-size: 1.4375rem;
  color: #B39158;
  margin-right: 0.875rem; }

.contact__link {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  color: #000; }
  .contact__link.white {
    color: #fff; }
  .contact__link:hover {
    color: #B39158; }

.header {
  height: 79px;
  background-color: #fff;
  width: 100%;
  position: fixed;
  z-index: 1000; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .header {
      border-bottom: 1px solid #5A5A5A; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .header {
      border-bottom: 1px solid #5A5A5A; } }
  .header .container {
    height: 100%;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__nav {
    height: 100%; }
    .header__nav .nav {
      height: 100%; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .header__nav {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .header__nav {
        display: none; } }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .header__contacts {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .header__contacts {
      display: none; } }
  .header .nav__link {
    display: inline-flex;
    position: relative;
    align-items: center;
    border-bottom: 5px solid transparent; }
    .header .nav__link.active, .header .nav__link:hover {
      color: #B39158;
      border-bottom: 5px solid #B39158; }

.footer {
  padding: 48px 0;
  background-color: #2D2D2D;
  color: #fff; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .footer {
      padding: 30px 0; } }
  .footer .section__wrapper {
    max-width: 1600px; }
  .footer-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .footer-top {
        display: none; } }
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .footer-bottom {
        flex-direction: column; }
        .footer-bottom .footer__copy {
          order: 3;
          margin: 0; }
        .footer-bottom .footer__link {
          margin-bottom: 15px; }
        .footer-bottom .footer__contacts {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px; }
          .footer-bottom .footer__contacts .contact__item {
            margin-bottom: 15px; } }
  .footer .logo {
    position: absolute;
    left: 0;
    top: 0; }
  .footer__nav {
    margin-left: 5%;
    align-self: center; }
  .footer__copy, .footer__link {
    display: block;
    font-size: 0.6875rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.61); }
  .footer__contacts {
    display: flex;
    align-items: center; }
  .footer .contact__link:first-of-type {
    margin-right: 2rem; }
  .footer .nav__link {
    color: #fff; }
    .footer .nav__link.active, .footer .nav__link:hover {
      color: #B39158; }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .footer {
      padding: 3rem 0; }
      .footer .logo-inverse {
        position: static;
        margin: 0 auto; }
      .footer .footer__nav {
        display: none; }
      .footer .footer-bottom {
        flex-wrap: wrap; }
      .footer .footer__copy {
        width: 100%;
        order: 1;
        text-align: center;
        margin: 0; }
      .footer .footer__contacts {
        margin: 1.3rem 0; } }

.order-form {
  min-width: 320px;
  max-width: 30%;
  width: 100%;
  background-color: #B39158;
  padding: 4rem 2rem 2rem; }
  .order-form .fancybox-close-small {
    left: auto;
    right: 0;
    top: .5rem; }
    .order-form .fancybox-close-small svg {
      fill: #7b5d30;
      stroke: #7b5d30; }
  .order-form .inputs__holder {
    flex-direction: column;
    margin-bottom: 1rem; }
    .order-form .inputs__holder .form-input {
      max-width: 100%;
      width: 100%;
      background-color: rgba(123, 93, 48, 0.65);
      margin-bottom: 1.5rem; }
  .order-form .button {
    display: inline-flex;
    float: right; }

.section-hero {
  height: 100vh;
  background-position: center;
  background-size: cover; }
  .section-hero .section__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .section-hero .section__wrapper {
        height: calc(100% - 150px); } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-hero .section__wrapper {
        text-align: center; }
        .section-hero .section__wrapper .button {
          margin: 0 auto; } }

.hero__title {
  font-size: 3rem;
  color: #fff;
  font-weight: 300;
  line-height: 4.125rem; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .hero__title {
      font-size: 1.625rem;
      line-height: 2.25rem; }
      .hero__title br {
        display: none; } }
  .hero__title b {
    font-weight: 600; }

.hero__text {
  font-size: 1.125rem;
  color: #fff;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 4.625rem; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .hero__text {
      font-size: 1rem;
      margin-bottom: 3.125rem; } }

.section-registration {
  padding-top: 10rem;
  padding-bottom: 10.3125rem; }
  .section-registration .section__title {
    font-weight: 300;
    margin-bottom: 3.3125rem; }
    .section-registration .section__title b {
      font-weight: 600; }
  .section-registration .content__wrapper {
    width: 100%;
    background-color: #B39158;
    display: flex; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .section-registration .content__wrapper {
        flex-direction: column-reverse;
        background-color: #fff; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-registration .content__wrapper {
        flex-direction: column-reverse;
        background-color: #fff;
        align-items: center; } }
  .section-registration .form__holder {
    max-width: 480px;
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 4.6875rem 0;
    background-color: #B39158; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .section-registration .form__holder {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        padding: 60px 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-registration .form__holder {
        margin: 0 auto;
        max-width: 75%;
        padding: 4rem 0; } }
  .section-registration .checkbox-container {
    max-width: 325px;
    margin: 0 auto; }
  .section-registration .video__holder {
    flex: 1 0 auto;
    position: relative;
    max-width: 800px;
    overflow: hidden; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .section-registration .video__holder {
        height: 345px;
        min-height: 345px;
        margin-bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-registration .video__holder {
        max-width: 75%;
        width: 100%; } }
    .section-registration .video__holder iframe {
      width: 100%;
      height: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-registration .video__holder {
        height: 400px;
        min-height: 400px;
        margin-bottom: 30px; } }
  .section-registration .video__button {
    display: flex;
    border: none;
    border-radius: 0;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: all 0.5s ease; }
    .section-registration .video__button i {
      font-size: 1.25rem;
      color: #B39158;
      transition: all 0.5s ease; }
    .section-registration .video__button:hover {
      background: #B39158; }
      .section-registration .video__button:hover i {
        color: #fff; }
  .section-registration .video__poster {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center; }

.form-registration .inputs__holder {
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem; }

.form-registration .form-input {
  border: 2px solid transparent;
  border-radius: 0; }
  .form-registration .form-input:not(:last-of-type) {
    margin-bottom: 1rem; }

.form-registration .button {
  margin-bottom: 3.5rem; }

.form-registration .checkbox-holder {
  max-width: 330px;
  margin: 0 auto; }

.form__title {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 2rem;
  margin-top: 0;
  margin-bottom: 3.125rem; }
  .form__title b {
    font-weight: 700; }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .section.section-registration {
    padding-bottom: 0; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section.section-registration {
    padding: 3rem 0; } }

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .section.section-registration {
    padding: 4rem 0; } }

.form-input.error {
  border: 2px solid red !important; }

.help-block.form-error {
  display: none !important; }

.btn-submit {
  width: 220px; }

.link-politic {
  display: inline-flex;
  text-decoration: underline;
  color: #ffe7c3;
  outline: none; }
  .link-politic:hover {
    color: #fff; }

#politic {
  width: 100%;
  max-width: 50%; }
  #politic .fancybox-close-small {
    right: 0;
    left: auto;
    top: .5rem; }

.checkbox-box .checkbox-content {
  display: block; }

.section-company {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 700px;
  background-position: center; }
  .section-company .section__title {
    margin-bottom: 1.875rem; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .section-company {
      height: auto;
      min-height: auto; }
      .section-company .items__container {
        flex-direction: column;
        align-items: center; }
      .section-company .item {
        width: 100%;
        text-align: center;
        margin-bottom: 30px; }
        .section-company .item br {
          display: none; } }

.items__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.item__count {
  font-size: 5rem; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .item__count {
      font-size: 4rem; } }

.item__desc {
  line-height: 1.625rem;
  font-size: 1rem;
  color: #fff; }

.section__wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px; }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section-company .items__container {
    flex-wrap: wrap; }
    .section-company .items__container .item {
      width: 50%;
      text-align: center;
      margin-bottom: 2rem; }
    .section-company .items__container .item__count {
      font-size: 4rem; } }

.section-light {
  padding-top: 175px;
  padding-bottom: 160px; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .section-light {
      padding: 60px 0; } }
  .section-light .section__title {
    margin-bottom: 35px; }
  .section-light p {
    color: #5A5A5A; }
    .section-light p b {
      font-weight: 600; }

.images__container {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: space-between; }
  .images__container .image {
    max-width: 380px; }
    .images__container .image__item {
      display: block;
      width: 100%;
      height: auto; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .images__container {
      flex-direction: column;
      padding: 35px 0; }
      .images__container .image:not(:last-of-type) {
        margin-bottom: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section.section-light {
    padding: 3rem 0; }
    .section.section-light .images__container {
      padding: 3rem 0; }
      .section.section-light .images__container .image {
        width: 30%; } }

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .section.section-light {
    padding: 4rem 0; }
    .section.section-light .images__container {
      padding: 2.5rem 0; } }

.section-form {
  background-color: #B39158;
  color: #fff;
  padding-top: 185px;
  padding-bottom: 72px; }
  .section-form .section__wrapper {
    max-width: 992px;
    width: 100%;
    text-align: center; }
  .section-form .section__title {
    margin-bottom: 50px; }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      .section-form .section__title {
        font-size: 1.125rem;
        line-height: 2rem; }
        .section-form .section__title br {
          display: none; } }
  .section-form .button {
    display: inline-block;
    margin-bottom: 56px; }
  .section-form .checkbox-container {
    max-width: 640px;
    margin: 0 auto; }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .section-form .inputs__holder {
      flex-direction: column;
      margin-bottom: 2.5rem; }
    .section-form .form-input {
      margin: 0 auto 1rem;
      border-radius: 0; } }

.inputs__holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5rem; }

.form-input {
  font-family: "montserrat", sans-serif;
  background-color: rgba(123, 93, 48, 0.65);
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: #fff;
  border: 2px solid transparent !important;
  padding: 13px 25px;
  max-width: 320px;
  width: 100%;
  transition: all 0.5s ease; }
  .form-input:focus {
    background: #7B5D30; }
  .form-input::-webkit-input-placeholder {
    font-family: "montserrat", sans-serif;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 400; }
  .form-input::-moz-placeholder {
    font-family: "montserrat", sans-serif;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 400; }
  .form-input:-ms-input-placeholder {
    font-family: "montserrat", sans-serif;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 400; }
  .form-input:-moz-placeholder {
    font-family: "montserrat", sans-serif;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 400; }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section.section-form {
    padding: 4rem 0; }
    .section.section-form .inputs__holder {
      flex-direction: column;
      margin: 0 auto;
      max-width: 75%;
      margin-bottom: 2rem; }
      .section.section-form .inputs__holder .form-input {
        max-width: 100%;
        margin-bottom: 1rem;
        border-radius: 0; }
    .section.section-form .checkbox-container {
      margin-bottom: 3rem;
      max-width: 75%; }
    .section.section-form .button {
      margin: 0; } }

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .section.section-form {
    padding: 6rem 0; } }

.section-projects {
  padding-top: 10.625rem;
  padding-bottom: 10rem; }
  .section-projects .section__title {
    font-weight: 300;
    margin-bottom: 3.3125rem; }
    .section-projects .section__title b {
      font-weight: 600; }
  .section-projects .item:focus {
    border: 0;
    outline: none; }
  .section-projects .item__desc {
    font-size: 1.125rem;
    line-height: 2rem;
    font-weight: 600;
    color: #000;
    max-width: 380px; }
  .section-projects .item__img {
    max-width: 380px;
    margin-bottom: 1.625rem; }
  .section-projects .slick-prev,
  .section-projects .slick-next {
    top: 35%; }
  .section-projects .slick-prev {
    left: -70px; }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-projects .slick-prev {
        left: -27px; } }
  .section-projects .slick-prev:hover:before,
  .section-projects .slick-prev:focus:before,
  .section-projects .slick-next:hover:before,
  .section-projects .slick-next:focus:before {
    color: #B39158; }

.slick-prev:before,
.slick-next:before {
  opacity: 1;
  transition: all 0.5s ease;
  font-family: "lightpoint";
  color: #000; }

.slick-prev:before {
  content: "\e900"; }

.slick-next:before {
  content: "\e901"; }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section.section-projects {
    padding: 3rem 0; }
    .section.section-projects .item {
      padding: .5rem; }
      .section.section-projects .item .item__img {
        display: block;
        width: 100%;
        height: auto; } }

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .section.section-projects {
    padding: 4rem 0; }
    .section.section-projects .slick-slider .item {
      padding: 1rem; }
    .section.section-projects .slick-next {
      right: -10px; }
    .section.section-projects .slick-prev {
      left: -25px; } }

.page__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%; }

.page-order {
  display: flex;
  flex-direction: column; }
  .page-order .main {
    background-color: #B39158;
    flex: 1 0 auto;
    display: flex; }
  .page-order .left-side {
    padding-top: 60px;
    padding-left: 9%;
    background: #fff;
    flex: 1 0 auto;
    max-width: 40%;
    color: #000; }
    .page-order .left-side .section__title {
      display: inline-block; }
      .page-order .left-side .section__title + p {
        margin-bottom: 36px; }
  .page-order .right-side {
    padding-top: 100px;
    width: 60%;
    color: #fff; }

.sc-product-cart {
  max-width: 800px;
  width: 100%;
  margin: 0 auto; }

.sc-product-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-right: 40px; }

.sc-product-item {
  margin-bottom: 2.8125rem; }

.sc-product-link {
  max-width: 320px;
  position: relative;
  border: 5px solid transparent;
  transition: all 0.5s ease;
  margin-bottom: 1.125rem; }
  .sc-product-link::after {
    display: none;
    content: attr(data-price) " руб.";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #B39158;
    color: #fff;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    font-weight: 600;
    padding: 8px 17px; }
  .sc-product-link:hover {
    border-color: #B39158; }

.sc-product-image {
  display: block;
  width: 100%;
  height: auto; }

.sc-product-desc {
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 600; }

.sc-cart-title {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-weight: 600;
  display: block;
  margin-bottom: 2rem; }
  .sc-cart-title__name {
    display: inline-block;
    padding-left: 40px;
    max-width: 55%;
    width: 100%;
    margin-right: 40px; }
  .sc-cart-title__total {
    display: none;
    margin-left: 34px; }

.sc-cart-container {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.sc-cart-item {
  padding: 20px 40px;
  min-height: 80px;
  background-color: rgba(123, 93, 48, 0.65);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1rem;
  line-height: 1.625rem; }
  .sc-cart-item__name {
    max-width: 55%;
    width: 100%;
    margin-right: 40px; }

.sc-cart-delete {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: none;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.5s ease;
  cursor: pointer; }
  .sc-cart-delete i {
    font-size: 0.75rem; }
  .sc-cart-delete:hover {
    background-color: #FF0000;
    color: white; }

.sc-cart-total {
  font-size: 1.125rem;
  font-weight: 600;
  color: #ffe7c3;
  line-height: 2rem;
  background-color: rgba(123, 93, 48, 0.65);
  padding: 8px 40px;
  margin-bottom: 2.5rem; }
  .sc-cart-total__name {
    display: inline-block;
    max-width: 55%;
    width: 100%;
    margin-right: 40px; }
  .sc-cart-total__count {
    display: inline-block;
    margin-left: 27px; }
  .sc-cart-total__price {
    display: inline-block;
    margin-left: 75px; }
  .sc-cart-total__nothing {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.625rem;
    color: #fff;
    height: 64px;
    display: flex;
    align-items: center; }

.sc-cart-button {
  display: flex;
  margin-left: auto; }

#total-cart-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.substract-item,
.add-item {
  width: 20px;
  height: 20px;
  border: none;
  background-color: #fff;
  font-weight: bold;
  cursor: pointer; }

.add-item {
  margin-left: 12px;
  margin-right: 45px; }

.substract-item {
  margin-right: 12px; }

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .page-order .left-side {
    padding-top: 40px;
    padding-left: 8%; }
  .page-order .right-side {
    padding: 100px 30px; } }

@media screen and (max-width: 1024px) {
  .page-order .main {
    display: flex;
    flex-direction: column; }
  .page-order .left-side {
    width: 100%;
    max-width: 100%;
    padding: 0;
    padding-top: 2rem; }
  .page-order .right-side {
    width: 100%;
    padding: 2rem;
    max-width: 100%; }
  .page-order .section__title {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem; } }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .page-order .sc-product-list {
    margin: 0 auto; }
  .page-order .right-side {
    padding: 15px; }
  .page-order .sc-cart-item {
    padding-left: 1rem;
    padding-right: 50px;
    font-size: 0.6rem;
    line-height: 1rem; }
    .page-order .sc-cart-item__name {
      margin-right: 24px; }
  .page-order .sc-cart-total {
    font-size: 0.7rem;
    line-height: 1rem;
    padding-left: 1rem; }
  .page-order .sc-cart-title__name {
    padding-left: 15px;
    max-width: 100%;
    width: 45%; }
  .page-order .sc-cart-title__count {
    text-align: center;
    display: inline-block; }
  .page-order .sc-cart-title__total {
    text-align: center;
    display: inline-block;
    margin-left: 7px; }
  .page-order .sc-cart-title {
    margin-left: 0;
    display: flex; }
  .page-order .sc-cart-title {
    margin-bottom: 1rem; }
  .page-order .sc-cart-total__count {
    margin-left: 5px; }
  .page-order .sc-cart-total__price {
    margin-left: 40px; }
  .page-order .substract-item {
    margin-right: 10px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .page-order .sc-product-list {
    max-width: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .page-order .sc-product-item {
    width: 47%; } }

.success-message {
  padding: 100px 100px;
  text-align: center; }

.success-title {
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3.125rem; }

.success-text {
  color: #5a5a5a;
  font-size: 1rem;
  line-height: 1.625rem; }

.fancybox-close-small {
  top: 2%;
  transform: translateX(-50%);
  opacity: 1; }
  .fancybox-close-small svg {
    fill: #B39158;
    stroke: #B39158; }

#total-cart-submit {
  margin-bottom: 2.5rem; }

.total-cart-text {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.625rem;
  color: #fff; }
