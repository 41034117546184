.section-projects {
  padding-top: + rem(170px);
  padding-bottom: + rem(160px);

  .section__title {
    font-weight: 300;
    margin-bottom: + rem(53px);

    b {
      font-weight: 600;
    }
  }

  .item {
    &:focus {
      border: 0;
      outline: none;
    }
  }

  .item__desc {
    font-size: + rem(18px);
    line-height: 2rem;
    font-weight: 600;
    color: $main-black;
    max-width: 380px;
  }

  .item__img {
    max-width: 380px;
    margin-bottom: + rem(26px);
  }

  .slick-prev,
  .slick-next {
    top: 35%;
  }

  .slick-prev {
    left: -70px;

    @include tablets {
      left: -27px;
    }
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    color: $accent;
  }
}

.slick-prev:before,
.slick-next:before {
  opacity: 1;
  transition: all 0.5s ease;
  font-family: "lightpoint";
  color: $main-black;
}

.slick-prev:before {
  content: "\e900";
}

.slick-next:before {
  content: "\e901";
}

.section.section-projects {
  @include tablets {
    padding: 3rem 0;

    .item {
      padding: .5rem;
      .item__img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding: 4rem 0;

    .slick-slider {
      .item {
        padding: 1rem;
      }
    }

    .slick-next {
      right: -10px;
    }

    .slick-prev {
      left: -25px;
    }
  }

}
