.section-light {
  padding-top: 175px;
  padding-bottom: 160px;

  @include phones {
    padding: 60px 0;
  }

  .section__title {
    margin-bottom: 35px;
  }

  p {
    color: $gray;

    b {
      font-weight: 600;
    }
  }
}

.images__container {
  width: 100%;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;

  .image {
    max-width: 380px;

    &__item {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @include phones {
    flex-direction: column;
    padding: 35px 0;

    .image {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }
  }
}

.section.section-light {
  @include tablets {
    padding: 3rem 0;

    .images__container {
      padding: 3rem 0;

      .image {
        width: 30%;
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding: 4rem 0;

    .images__container {
     padding: 2.5rem 0 ;
    }
  }
}
