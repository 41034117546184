.page-order {
  display: flex;
  flex-direction: column;

  .main {
    background-color: $accent;
    flex: 1 0 auto;
    display: flex;
  }

  .left-side {
    padding-top: 60px;
    padding-left: 9%;
    background: $main-white;
    flex: 1 0 auto;
    max-width: 40%;
    color: $main-black;

    .section__title {
      display: inline-block;

      & + p {
        margin-bottom: 36px;
      }
    }
  }

  .right-side {
    padding-top: 100px;
    width: 60%;
    color: $main-white;
  }
}

.sc-product {
  &-cart {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-right: 40px;
  }

  &-item {
    margin-bottom: +rem(45px);
  }

  &-link {
    max-width: 320px;
    position: relative;
    border: 5px solid transparent;
    transition: all 0.5s ease;
    margin-bottom: +rem(18px);

    &::after {
      display: none;
      content: attr(data-price) " руб.";
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $accent;
      color: $main-white;
      font-size: +rem(12px);
      line-height: +rem(15px);
      font-weight: 600;
      padding: 8px 17px;
    }

    &:hover {
      border-color: $accent;
    }
  }

  &-image {
    display: block;
    width: 100%;
    height: auto;
  }

  &-desc {
    font-size: +rem(18px);
    line-height: +rem(32px);
    font-weight: 600;
  }
}

.sc-cart {
  &-title {
    font-size: +rem(12px);
    line-height: +rem(15px);
    font-weight: 600;
    display: block;
    margin-bottom: 2rem;

    &__name {
      display: inline-block;
      padding-left: 40px;
      max-width: 55%;
      width: 100%;
      margin-right: 40px;
    }

    &__total {
      display: none;
      margin-left: 34px;
    }
  }

  &-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &-item {
    padding: 20px 40px;
    min-height: 80px;
    background-color: rgba(123, 93, 48, 0.65);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1rem;
    line-height: +rem(26px);

    &__name {
      max-width: 55%;
      width: 100%;
      margin-right: 40px;
    }
  }

  &-delete {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $main-white;
    border: none;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transition: all 0.5s ease;
    cursor: pointer;

    i {
      font-size: +rem(12px);
    }

    &:hover {
      background-color: $main-red;
      color: white;
    }
  }

  &-total {
    font-size: +rem(18px);
    font-weight: 600;
    color: #ffe7c3;
    line-height: 2rem;
    background-color: rgba(123, 93, 48, 0.65);
    padding: 8px 40px;
    margin-bottom: +rem(40px);

    &__name {
      display: inline-block;
      max-width: 55%;
      width: 100%;
      margin-right: 40px;
    }

    &__count {
      display: inline-block;
      margin-left: 27px;
    }

    &__price {
      display: inline-block;
      margin-left: 75px;
    }

    &__nothing {
      font-size: 1rem;
      font-weight: normal;
      line-height: +rem(26px);
      color: $main-white;
      height: 64px;
      display: flex;
      align-items: center;
    }
  }

  &-button {
    display: flex;
    margin-left: auto;
  }
}

#total-cart-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.substract-item,
.add-item {
  width: 20px;
  height: 20px;
  border: none;
  background-color: $main-white;
  font-weight: bold;
  cursor: pointer;
}

.add-item {
  margin-left: 12px;
  margin-right: 45px;
}

.substract-item {
  margin-right: 12px;
}

.page-order {
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    .left-side {
      padding-top: 40px;
      padding-left: 8%;
    }

    .right-side {
      padding: 100px 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    .main {
      display: flex;
      flex-direction: column;
    }

    .left-side {
      width: 100%;
      max-width: 100%;
      padding: 0;
      padding-top: 2rem;
    }

    .right-side {
      width: 100%;
      padding: 2rem;
      max-width: 100%;
    }

    .section__title {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  @include phones {
    .sc-product-list {
      margin: 0 auto;
    }

    .right-side {
      padding: 15px;
    }

    .sc-cart-item {
      padding-left: 1rem;
      padding-right: 50px;
      font-size: 0.6rem;
      line-height: 1rem;

      &__name {
        margin-right: 24px;
      }
    }

    .sc-cart-total {
      font-size: 0.7rem;
      line-height: 1rem;
      padding-left: 1rem;
    }
    .sc-cart-title__name {
      padding-left: 15px;
      max-width: 100%;
      width: 45%;
    }

    .sc-cart-title__count {
      text-align: center;
      display: inline-block;
    }

    .sc-cart-title__total {
      text-align: center;
      display: inline-block;
      margin-left: 7px;
    }

    .sc-cart-title {
      margin-left: 0;
      display: flex;
    }

    .sc-cart-title {
      margin-bottom: 1rem;
    }

    .sc-cart-total__count {
      margin-left: 5px;
    }

    .sc-cart-total__price {
      margin-left: 40px;
    }

    .substract-item {
      margin-right: 10px;
    }
  }

  @include tablets {
    .sc-product-list {
      max-width: 100%;
      padding: 0 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .sc-product-item {
      width: 47%;
    }
  }
}

.success {
  &-message {
    padding: 100px 100px;
    text-align: center;
  }

  &-title {
    font-weight: 600;
    font-size: +rem(36px);
    line-height: +rem(50px);
  }

  &-text {
    color: #5a5a5a;
    font-size: 1rem;
    line-height: +rem(26px);
  }
}

.fancybox-close-small {
  //left: 50%;
  top: 2%;
  transform: translateX(-50%);
  opacity: 1;
  svg {
    fill: $accent;
    stroke: $accent;
  }
}

#total-cart-submit {
  margin-bottom: 2.5rem;
}

.total-cart-text {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.625rem;
  color: $main-white;
}
